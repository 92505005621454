@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Inter&family=Lato&display=swap");

body {
    @apply bg-white;
}

html.dark {
    body {
        @apply bg-black;
    }
}

:root {
  --primary: 12, 12, 15;
  --secondary: 255, 201, 20;
  --white: 249, 244, 244;
  --black: 0, 0, 0;
  --dark-gray: 46, 46, 46;
  --blue: 17, 103, 255;
}


*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
  color-scheme: dark;
  color: var(--primary);
}

a {
  color: inherit;
  text-decoration: none;
}

/* ==== title ==== */
h1 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: rgba(var(--primary), 1);
}

h3,
h4 {
  text-transform: uppercase;
  color: rgba(var(--primary), 1);
  font-weight: 900;
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: rgba(var(--secondary), 1);
}

/* ==== navbar ==== */
header {
  width: 100%;
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  z-index: 10;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

nav > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
